/**
 * Created by jerry on 2020/03/30.
 * 储值卡销售提佣 api
 */
import * as API from '@/api/index'

export default {
    // 获取储值卡销售组织单位提成方案列表
    getSavingCardCommissionScheme: params => {
        return API.POST('api/saleSavingCardCommissionScheme/list', params)
    },
    // 创建储值卡销售组织单位提成方案
    createSavingCardCommissionScheme: params => {
        return API.POST('api/saleSavingCardCommissionScheme/create', params)
    },
    //删除储值卡销售提成方案
    deleteSavingCardCommissionScheme: params => {
        return API.POST('api/saleSavingCardCommissionScheme/delete', params)
    },
    // 获取储值卡销售分类提佣方案
    getSavingCardCategoryCommission: params => {
        return API.POST('api/saleSavingCardCategoryCommission/all', params)
    },


    //保存储值卡销售分类提成方案
    updateSavingCardCategoryCommission: params => {
        return API.POST('api/saleSavingCardCategoryCommission/update', params)
    },
    //获取储值卡销售分类经手人提佣方案
    getSavingCardCategoryHandlerCommission: params => {
        return API.POST('api/saleSavingCardCategoryHandlerCommission/all', params)
    },
    //保存储值卡销售分类经手人提成方案
    updateSavingCardCategoryHandlerCommission: params => {
        return API.POST('api/saleSavingCardCategoryHandlerCommission/update', params)
    },
    //获取分类下的储值卡提佣方案
    getSavingCardCommission: params => {
        return API.POST('api/saleSavingCardCommission/all', params)
    },
    //保存储值卡销售提成方案
    updateSavingCardCommission: params => {
        return API.POST('api/saleSavingCardCommission/update', params)
    },
    //获取储值卡下经手人提佣方案
    getSavingCardHanderCommission: params => {
        return API.POST('api/saleSavingCardHandlerCommission/all', params)
    },
    //保存储值卡下经手人提成方案
    updateSavingCardHanderCommission: params => {
        return API.POST('api/saleSavingCardHandlerCommission/update', params)
    },
    //获取所有储值卡经手人提佣方案
    saleSavingCardSchemeHanderCommission: params => {
        return API.POST('api/saleSavingCardSchemeHandlerCommission/all', params)
    },
    //保存所有储值卡经手人提佣方案
    saleSavingCardSchemeHanderCommissionupdate: params => {
        return API.POST('api/saleSavingCardSchemeHandlerCommission/update', params)
    },

    // 35.8.获取套餐卡储值卡销售分类经手人提佣方案
    getPackageSaleSavingCardCategoryHandlerCommission: params => {
        return API.POST('api/saleSavingCardCategoryHandlerCommission/packageCard', params)
    },
    // 35.13.获取套餐卡储值卡经手人提佣方案
    getPackageSaleSavingCardHandlerCommission: params => {
        return API.POST('api/saleSavingCardHandlerCommission/packageCard', params)
    },
    // 35.16.获取所有套餐卡储值卡经手人提佣方案
    getPackageSaleSavingCardSchemeHandlerCommission: params => {
        return API.POST('api/saleSavingCardSchemeHandlerCommission/packageCard', params)
    },

}